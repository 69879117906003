import { DatePicker } from "antd";
import LineChart from "../../components/Analytics/Charts/LineChart";
import AnaltyicsTable from "../../components/Analytics/Table/Analytics";
import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import "./styles.css";
import Button from "../../components/Button";
import { color } from "../../constant/color";
import moment from "moment";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "../../utils/axios";
import { companyController } from "../../Controller/CompanyController";

const Analytics = () => {
  const { RangePicker } = DatePicker;
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract(6, "month").format("YYYY-MM-DD HH:mm:ss")
  );
  const controller = new AbortController();
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  // const [count, setCount] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  // const [tabIndexH, setTabIndexH] = useState(1);
  const [datapoints, setdatapoints] = useState({
    // totalactiveenergyln1: 0,
    avg_frequency: 0,
    avg_current: 0,
    avg_voltage: 0,
    avg_power: 0,
    total_energy: 0,
    // avgapparentpowerln1: 0,
    // totalactiveenergyln1: 0,
  });
  const [loading, setLoading] = useState({
    deviceLoading: false,
  });
  const [dcuid, setDcuid] = useState([]);
  const [meterids, setMeterIds] = useState([]);

  const [startEnergy, setStartenergy] = useState(0);
  const [endEnergy, setEndenergy] = useState(0);
  const [startCurrent, setstartCurrent] = useState(0);
  const [endCurrent, setendCurrent] = useState(0);
  const [startVoltage, setstartVoltage] = useState(0);
  const [endVoltage, setendVoltage] = useState(0);
  const [startPf, setStartPf] = useState(0);
  const [endPf, setEndPf] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [startFrequency, setstartFrequency] = useState(0);
  const [endFrequency, setendFrequency] = useState(0);
  const [startApparentpower, setStartApparentpower] = useState(0);
  const [endApparentpower, setEndApparentpower] = useState(0);
  const [startRealPower, setStartRealPower] = useState(0);
  const [endRealPower, setEndRealPower] = useState(0);
  const [data, setData] = useState([]);
  const [tabledata, settabledata] = useState([]);
  // const [modal, setModal] = useState(false);
  // const [projectSiteData, setProjectSiteData] = useState([]);
  // const [error, setError] = useState("");
  // const [success, setSuccess] = useState("");
  // const [dataFromHeadend, setDataFromHeadend] = useState(false);
  // const [headendFilter, setHeadendFilter] = useState(false);
  // const [projectFilter, setprojectFilter] = useState(false);
  // const [dataFromProjectSite, setdataFromProjectSite] = useState(false);
  // const [showHeadendChart, setshowHeadendChart] = useState(false);
  // const [showProjectSiteChart, setshowProjectSiteChart] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/energyanalytics/getbasicpowerquality",
          // "/managementsystem/energyanalytics/getpowerqualitydataheadend",
          {
            // dcuid: JSON.stringify([]),
            // endapparentpower: endApparentpower,
            // endcurrent: endCurrent,
            enddate: enddate,
            // endenergy: endEnergy,
            // endfrequency: endFrequency,
            // endpf: endPf,
            // endrealpower: endRealPower,
            // endvoltage: endVoltage,
            meterids: JSON.stringify(["3550000000201"]),
            // startapparentpower: startApparentpower,
            // startcurrent: startCurrent,
            startdate: startdate,
            // startenergy: startEnergy,
            // startfrequency: startFrequency,
            // startpf: startPf,
            // startrealpower: startRealPower,
            // startvoltage: startVoltage,
          }
        );
        let result = companyController(res);

        if (result.type !== "success") {
          toast.error("An error occured while fetching power quality data.");
          return;
        }

        console.log(result, "result");
        // console.log(result.message.body);
        // console.log(result);
        // setdatapoints(() => {
        //   return { ...result.message.meta };
        // });
        // // console.log(result.message.meta, "result");

        // settabledata((prev) => result.message.body.splice(0, 100));
        // setData((prev) => result.message.body.splice(0, 100));

        // console.log(result.message.body[0]);
      } catch (err) {
        toast.error(err.message, {
          toastId: "e3osdj",
        });
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);
  return (
    <Layout>
      <TopNav />
      <div className="px-3">
        <div className="analytics-cover container-fluid bg-cover bg-white shadow-sm mt-5 p-2 ">
          <div className="row my-2 d-flex align-items-center justify-content-between">
            <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
              <div className="page-title">Power quality data</div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
              <Button
                text={"Filter"}
                bgColor={color.primary}
                color={color.white}
              />
            </div>
          </div>
          <div className=" row  w-100 d-flex align-items-center justify-content-between">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div
                className="d-flex align-items-center"
                style={{
                  borderBottom: "1px solid #eee",
                }}
              >
                <div
                  className="mx-2 px-4 py-1"
                  style={{
                    color: tabIndex === 1 ? color.primary : color.black,
                    borderBottom:
                      tabIndex === 1 ? `2px solid ${color.primary}` : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setTabIndex(1)}
                >
                  Charts
                </div>
                <div
                  className="mx-2 px-4 py-1"
                  style={{
                    color: tabIndex === 2 ? color.primary : color.black,
                    borderBottom:
                      tabIndex === 2 ? `2px solid ${color.primary}` : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setTabIndex(2)}
                >
                  Tables
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <DatePicker.RangePicker
                allowClear
                autoFocus
                showTime
                size={"large"}
                onChange={(e) => {
                  setstartdate(moment(e[0]._d).format("YYYY-MM-DD HH:mm:ss"));
                  setenddate(moment(e[1]._d).format("YYYY-MM-DD HH:mm:ss"));
                  setRefresh((prev) => !prev);
                }}
              />
            </div>
            <div className=""></div>
          </div>
          <div className="w-100 my-2">
            <div className="data-point p-2 rounded-2">
              <div className="text-center">
                Avarage data points from{" "}
                <span className="date-range">{startdate} </span> to{" "}
                <span className="date-range">{enddate}</span>
              </div>
              <div className="row mt-2 d-flex align-items-center justify-content-center">
                <div className="col-sm-5 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="data-point__entry d-flex flex-column align-items-center justify-content-center">
                    {/* <div className="data-point__icon">Frequency</div> */}
                    <div className="data-point__title">Frequency.</div>
                    <div className="data-point__value">
                      {!datapoints ? "0.00" : datapoints?.avg_frequency}
                      {/* {!Object.keys(datapoints).length
                        ? "0"
                        : datapoints.avgfrequencyln1 === null
                        ? 0
                        : isNaN(datapoints.avgfrequencyln1)
                        ? 0
                        : datapoints.avgfrequencyln1} */}
                      .Hz
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="data-point__entry d-flex flex-column align-items-center justify-content-center">
                    {/* <div className="data-point__icon">Frequency</div> */}
                    <div className="data-point__title">Voltage.</div>
                    <div className="data-point__value">
                      {!datapoints ? "0.00" : datapoints?.avg_voltage}
                      {/* "meta": {
        "datapointcount": 76,
        "total_energy": "00.09819",
        "avg_voltage": "195.51879",
        "avg_current": "00.13025",
        "avg_pf": "01.00",
        "avg_power": "15.95109",
        "avg_frequency": "50.23"
    }, */}
                      {/* {!Object.keys(datapoints).length
                        ? "0"
                        : datapoints.totalactiveenergyln1 === null
                        ? 0
                        : datapoints.totalactiveenergyln1} */}
                      .V
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="data-point__entry d-flex flex-column align-items-center justify-content-center">
                    {/* <div className="data-point__icon">Frequency</div> */}
                    <div className="data-point__title">Current.</div>
                    <div className="data-point__value">
                      {!datapoints ? "0.00" : datapoints?.avg_current}
                      {/* {!Object.keys(datapoints).length
                        ? "0"
                        : datapoints.avgcurrentln1 === null
                        ? 0
                        : isNaN(datapoints.avgcurrentln1)
                        ? 0
                        : datapoints.avgcurrentln1} */}
                      .A
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="data-point__entry d-flex flex-column align-items-center justify-content-center">
                    {/* <div className="data-point__icon">Frequency</div> */}
                    <div className="data-point__title">Power.</div>
                    <div className="data-point__value">
                      {!datapoints ? "0.00" : datapoints?.avg_power}
                      {/* {!Object.keys(datapoints).length
                        ? "0"
                        : datapoints.avgapparentpowerln1 === null
                        ? 0
                        : isNaN(datapoints.avgapparentpowerln1)
                        ? 0
                        : datapoints.avgapparentpowerln1} */}
                      .Watts
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                  <div className="data-point__entry d-flex flex-column align-items-center justify-content-center">
                    {/* <div className="data-point__icon">Frequency</div> */}
                    <div className="data-point__title">Energy.</div>
                    <div className="data-point__value">
                      {!datapoints ? "0.00" : datapoints?.total_energy}
                      {/* {!Object.keys(datapoints).length
                        ? "0"
                        : isNaN(datapoints.totalactiveenergyln1)
                        ? 0
                        : datapoints.totalactiveenergyln1} */}
                      .kWh
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <AnaltyicsTable /> */}
          {tabIndex === 1 && (
            <div className="">
              <LineChart data={data} />
            </div>
          )}
          {tabIndex === 2 && (
            <div className="">
              <AnaltyicsTable data={data} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;
