import moment from "moment";
import { useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import PageBar from "../../../components/PageBar";
import TopNav from "../../../components/TopNav";
import { Select } from "antd";
import "./style.css";
import { color } from "../../../constant/color";
import { HiOutlineClipboard } from "react-icons/hi";
import React, { useState, useEffect } from "react";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import CompanyImg from "../../../assets/svg/companyimg.svg";

import Button from "../../../components/Button";
const CompanyDetails = () => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [keys, setkeys] = useState(false);
  const { Option } = Select;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({
    nameLoading: false,
    phoneLoading: false,
    emailLoading: false,
    keyLoading: false,
    statusLoading: false,
    loadingData: false,
    updateAllLoading: false,
  });
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/management/getspecificcompanydata", {
          companyalias: id,
        });
        let result = companyController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }

        setLoading(false);

        setData(result.message);
      } catch (err) {
        toast.err(err.message);
      }
    })();
    return () => {};
  }, [refresh]);

  //  Rename
  const handleName = async () => {
    try {
      if (!name) return toast.error("Please enter a name.");
      setLoading({ nameLoading: true });
      let res = await axios.post("/management/updatecompanyname", {
        companyalias: id,
        newname: name,
      });
      let result = companyController(res);
      if (result.type !== "success") {
        toast.error(result.message, {
          toastId: "384809lm",
        });
        setLoading({ nameLoading: false });
        return;
      }
      toast.success("Name updateded.", {
        toastId: "34634fw1",
      });
      setLoading({ nameLoading: false });
      setRefresh((prev) => !prev);
      setName("");
    } catch (err) {
      toast.error(err.message);
      setLoading({ nameLoading: false });
    }
  };

  // Email
  const handleEmail = async () => {
    try {
      if (!email) return toast.error("Please enter an email address.");

      setLoading({ emailLoading: true });
      let res = await axios.post("/management/updatecompanyemail", {
        companyalias: id,
        newemailaddress: email,
      });
      let result = companyController(res);
      if (result.type !== "success") {
        toast.error(result.message, {
          toastId: "384809lm",
        });
        setLoading({ emailLoading: false });
        return;
      }
      toast.success("Email updateded.", {
        toastId: "34634fw1",
      });
      setLoading({ emailLoading: false });
      setRefresh((prev) => !prev);
      setEmail("");
    } catch (err) {
      toast.error(err.message);
      setLoading({ emailLoading: false });
    }
  };

  const handlePhone = async () => {
    try {
      if (!phone) return toast.error("Please enter a phone number.");

      setLoading({ phoneLoading: true });
      let res = await axios.post("/management/updatecompanyphone", {
        newphone: phone,
        companyalias: id,
      });
      if (res.status === 200) {
        setLoading({ phoneLoading: false });
        setRefresh((prev) => !prev);

        return;
      }
      let result = companyController(res);
      if (result.type !== "success") {
        toast.error(result.message, {
          toastId: "384809lm",
        });
        setLoading({ phoneLoading: false });
        return;
      }

      setPhone("");
      toast.success("Phone updateded.", {
        toastId: "34634fw1",
      });
      setLoading({ phoneLoading: false });
      setRefresh((prev) => !prev);
    } catch (err) {
      setLoading({ phoneLoading: false });
    }
  };

  const handlekeys = async () => {
    try {
      if (!data.secretkey)
        return toast.error(
          "You have to activate company to reset secret keys."
        );

      setLoading({ keyLoading: true });
      let res = await axios.post("/management/resetsecretkey", {
        companyalias: id,
      });
      if (res.status === 200) {
        setLoading({ keyLoading: false });
        setRefresh((prev) => !prev);

        return;
      }
      let result = companyController(res);
      if (result.type !== "success") {
        toast.error(result.message, {
          toastId: "384809lm",
        });
        setLoading({ keyLoading: false });
        return;
      }

      toast.success("Secret keys updated successfully.");
      setLoading({ keyLoading: false });
      setRefresh((prev) => !prev);
    } catch (err) {
      setLoading({ keyLoading: false });
    }
  };

  const handleAll = async () => {
    try {
      if (!email || !phone || !name)
        return toast.error("All values are required");
      setLoading({ ...setLoading, updateAllLoading: true });

      let res = await axios.post("/management/updateallcompanydata", {
        companyalias: id,
        newname: name,
        newphone: phone,
        newactivestatus: data.activestatus,
        newemailaddress: email,
      });
      let result = companyController(res);
      if (result.type !== "success") {
        toast.error(result.message, {
          toastId: "384809lm",
        });
        setLoading({ updateAllLoading: false });
        return;
      }
      toast.success("Updateded.", {
        toastId: "34634fw1",
      });

      setName("");
      setPhone("");
      setEmail("");
      setLoading({ updateAllLoading: false });
      setRefresh((prev) => !prev);
      return;
    } catch (err) {
      toast.error(err.message);
      setLoading({ updateAllLoading: false });
    }
  };

  const handleStatus = async () => {
    try {
      if (!status) return toast.error("Please select a state.");
      setLoading({ ...loading, statusLoading: true });
      if (status === "suspend") {
        let res = await axios.post("/management/deactivatecompany", {
          companyalias: id,
        });
        let result = companyController(res);
        if (result.type !== "success") {
          toast.error(result.message, {
            toastId: "384809lm",
          });
          setLoading({ ...loading, statusLoading: false });
          return;
        }

        toast.success("Company has been susended successfully.");
        setLoading({ ...loading, statusLoading: false });
        setRefresh((prev) => !prev);
      } else {
        let res = await axios.post("/management/activatecompany", {
          companyalias: id,
        });
        let result = companyController(res);
        if (result.type !== "success") {
          toast.error(result.message, {
            toastId: "384809lm",
          });
          setLoading({ ...loading, statusLoading: false });
          return;
        }
        toast.success("Activated company successfully.");
        setLoading({ ...loading, statusLoading: false });
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  return (
    <Layout>
      <TopNav />
      <div className="px-3 w-100">
        <PageBar
          title={`Company - ${
            !Object.keys(data).length ? "" : data.companyname
          }`}
          page={"company"}
        />
        <div
          className="container-fluid"
          style={{
            marginBottom: 300,
          }}
        >
          <div className="row mt-3 ">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 rounded-2 border shadow-sm">
              <div className="w-100  ">
                <div className="company-profile pb-2">
                  <img
                    src={CompanyImg}
                    alt={"company image"}
                    className="image-fluid"
                  />
                </div>
                <div className="w-100 d-flex pt-3 align-items-center justify-content-between">
                  <div className="company-entry_title">Company details</div>
                  <div
                    className=""
                    style={{
                      color: !Object.keys(data).length
                        ? ""
                        : data.activestatus === "inactive"
                        ? "tomato"
                        : color.green,
                      fontWeight: 510,
                    }}
                  >
                    {!Object.keys(data).length
                      ? ""
                      : data.activestatus.toUpperCase()}
                  </div>
                </div>
              </div>
              <div>
                <div className="company-entry py-2 my-2 d-flex align-items-center justify-content-between">
                  <div className="company-entry_title">Company name:</div>
                  <div className="company-entry_value">
                    {!Object.keys(data).length ? "" : data.companyname}
                  </div>
                </div>
                <div className="company-entry d-none py-2 my-2 d-flex align-items-center justify-content-between">
                  <div className="company-entry_title">Company alias:</div>
                  <div className="company-entry_value">
                    {!Object.keys(data).length ? "" : data.companyalias}
                  </div>
                </div>
                <div className="company-entry py-2 my-2 d-flex align-items-center justify-content-between">
                  <div className="company-entry_title">Company phone:</div>
                  <div className="company-entry_value">
                    {!Object.keys(data).length ? "" : data.phone}
                  </div>
                </div>
                <div className="company-entry py-2 my-2 d-flex align-items-center justify-content-between">
                  <div className="company-entry_title">Company email:</div>
                  <div className="company-entry_value">
                    {!Object.keys(data).length ? "" : data.email}
                  </div>
                </div>
                <div className="company-entry py-2 my-2 d-flex align-items-center justify-content-between">
                  <div className="company-entry_title">Date created:</div>
                  <div className="company-entry_value">
                    {!Object.keys(data).length
                      ? ""
                      : moment(data.datecreated).format("lll")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
              <div className="w-100 border bg-white rounded-2 shadow-sm">
                {/* Edit */}
                <div className="w-100 p-2">
                  <div>Update company details</div>
                  <div className="container-fluid">
                    {/* Email */}
                    <div className="row d-flex align-items-end mt-3">
                      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                        <div className="company-info__entry">
                          <div className="company-entry_title ">
                            Enter new email address
                          </div>
                          <input
                            type={"email"}
                            className={"company-email form-control"}
                            placeholder={"*************@gmail.com"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <div className="w-100" onClick={handleEmail}>
                          <Button
                            text={"Update"}
                            fontSize={"12px"}
                            fontWeight={"400"}
                            status={loading.emailLoading}
                            height={"40px"}
                            loaderColor={color.white}
                            color={color.white}
                            bgColor={color.primary}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Phone */}
                    <div className="row d-flex align-items-end mt-3">
                      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                        <div className="company-info__entry">
                          <div className="company-entry_title ">
                            Enter new phone number
                          </div>
                          <input
                            type={"number"}
                            className={"company-email form-control"}
                            placeholder={"08012345678"}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <div className="w-100" onClick={handlePhone}>
                          <Button
                            text={"Update"}
                            fontSize={"12px"}
                            fontWeight={"400"}
                            status={loading.phoneLoading}
                            height={"40px"}
                            loaderColor={color.white}
                            color={color.white}
                            bgColor={color.primary}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Name */}
                    <div className="row d-flex align-items-end mt-3">
                      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                        <div className="company-info__entry">
                          <div className="company-entry_title ">
                            Enter new name:
                          </div>
                          <input
                            type={"test"}
                            className={"company-email form-control"}
                            placeholder={"new name"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <div className="w-100" onClick={handleName}>
                          <Button
                            text={"Update"}
                            fontSize={"12px"}
                            fontWeight={"400"}
                            status={loading.nameLoading}
                            height={"40px"}
                            loaderColor={color.white}
                            color={color.white}
                            bgColor={color.primary}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex align-items-end mt-4">
                      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7"></div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <div className="w-100" onClick={handleAll}>
                          <Button
                            text={"Update All."}
                            fontSize={"12px"}
                            fontWeight={"400"}
                            status={loading.updateAllLoading}
                            height={"40px"}
                            loaderColor={color.white}
                            color={color.white}
                            bgColor={color.primary}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Activity */}
                <div className="w-100 p-2 ">
                  <div>Company activity</div>
                  <div className="container-fluid">
                    {/* Email */}
                    <div className="row d-flex align-items-end mt-3">
                      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                        <div className="company-info__entry">
                          <div className="">Enter new email address</div>
                          <Select
                            size="large"
                            placeholder="select company activity state."
                            style={{
                              width: "100%",
                            }}
                            value={status}
                            onChange={(e) => setStatus(e)}
                          >
                            <Option value={"activate"}>Activate</Option>
                            <Option value={"suspend"}>Suspend</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-sm-12   col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <div className="w-100" onClick={handleStatus}>
                          <Button
                            text={"Save"}
                            fontSize={"12px"}
                            fontWeight={"400"}
                            status={loading.statusLoading}
                            height={"40px"}
                            loaderColor={color.white}
                            color={color.white}
                            bgColor={color.primary}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Secret key */}
                    <div className="row d-flex align-items-end mt-3 pb-5">
                      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                        <div className="company-info__entry">
                          <div className="">Company secret key.</div>
                          <div className="w-100 key-cover border p-2 rounded-2 d-flex align-items-center justify-content-between">
                            <div
                              className={""}
                              onClick={() => {
                                if (data.secretkey === null) {
                                  return null;
                                } else {
                                  copy(data.secretkey);
                                  toast.success("secret key copied", {
                                    toastId: "Acasc899239",
                                  });
                                  return;
                                }
                              }}
                            >
                              {!Object.keys(data).length ? "" : data.secretkey}
                            </div>
                            <HiOutlineClipboard size={20} color={"#090909"} />
                            <div
                              className="key-plate shadow-sm rounded-2 text-center pt-2"
                              onClick={() => setkeys(true)}
                              style={{
                                display: keys ? "none" : "block",
                              }}
                            >
                              Reveal keys
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12  col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <div className="w-100" onClick={handlekeys}>
                          <Button
                            text={"Reset keys"}
                            fontSize={"12px"}
                            fontWeight={"400"}
                            status={loading.keyLoading}
                            height={"40px"}
                            loaderColor={color.white}
                            color={color.white}
                            bgColor={color.primary}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompanyDetails;
